import React, { useEffect } from 'react';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import Hero from '../components/homepage/Hero';
import Services from '../components/homepage/Services';
import DownloadApp from '../components/homepage/DownloadApp';
import Testimonials from '../components/homepage/Testimonials';
import Staff from '../components/homepage/Staff';
import Blog from '../components/homepage/Blog';
import Events from '../components/homepage/Events';
import Map from '../components/homepage/Map';
import '../styles/app.scss';
import { useTestimonials } from '../hooks/getHomeTestimonials';
import { useLatestBlog } from '../hooks/getLatestBlog';
import { useHomeSeo } from '../hooks/getHomeSEO';
import SEO from '../components/seo';

const IndexPage = () => {
    const dataTestimonials = useTestimonials();
    const testimonialsList = dataTestimonials.wordpressPage.acf;

    const dataSEO = useHomeSeo();
    const homeSEO = dataSEO.wordpressPage;

    const dataBlog = useLatestBlog();
    const blogList = dataBlog.allWordpressPost.nodes;
    
    let test_title = homeSEO.yoast_title;
    let new_seo_title = test_title.replace("&#039;", "'");

    useEffect(() => {
        // Inject the Elfsight script dynamically
        const scriptId = 'elfsight-platform';
        if (!document.getElementById(scriptId)) {
          const script = document.createElement('script');
          script.id = scriptId;
          script.src = 'https://static.elfsight.com/platform/platform.js';
          script.async = true;
          document.body.appendChild(script);
        } else if (window.ELFSIGHT) {
          // Reinitialize Elfsight widgets if script already exists
          window.ELFSIGHT.reload();
        }
      }, []);
      
    return (
        <>
            <SEO
                title={new_seo_title}
                meta={homeSEO.yoast_meta}
                description={homeSEO.excerpt}
            />
            <HeaderMain />
            <div
                className="elfsight-app-f9959696-a8c8-48e6-aea7-8fde04632397"
                data-elfsight-app-lazy
            ></div>
            <Hero />
            <Services />
            <DownloadApp />
            <Testimonials testimonialsList={testimonialsList} />
            <Staff bgType="secondary" />
            <Blog blogList={blogList} />
           {/* <Events />  */}
            <Map />								
            <Footer />
        </>
    );
};

export default IndexPage;
